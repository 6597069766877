<template>
  <div :class="'video-panel-' + playWnd" :style="videoSize">
    <div :id="playWnd" class="video-content"></div>
  </div>
</template>
<script>
/* eslint-disable no-undef */
import defaultInitConf from '@/conf/hikConf'
import { mapGetters } from 'vuex'
import { fn_util_debounce } from '@/util/util'
import Vue from 'vue'
// import { debuglog } from 'util';
// var [oWebControl] = null;
export default {
  name: 'VideoPanel',
  props: {
    value: {
      type: Array
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    appendVideo: {
      type: String,
      default: ''
    },
    // 功能函数名称
    funcName: {
      type: String,
      default: ''
    },
    // 是否轮询
    polling: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    showVideoLayout: {
      type: Number,
      default: 2
    },
    hideWnd: {
      type: Boolean,
      default: false
    },
    desVideo: {
      type: Boolean,
      default: false
    },
    left: {
      type: Number,
      default: 0
    },
    top: {
      type: Number,
      default: 0
    },
    playWnd: {
      type: String,
      default: 'playWnd'
    },
    oWebControl: {
      type: String,
      default: 'oWebControl'
    },
    lengthUnit: {
      type: String,
      default: 'vw'
    }
  },
  computed: {
    ...mapGetters(['zoom']),
    videoSize() {
      return {
        width: this.width ? this.width + this.lengthUnit : 'auto',
        height: this.height ? this.height + this.lengthUnit : 'auto'
      }
    },
    cameraCount() {
      if (this.polling) {
        return this.value.list
      }
      return 0
    }
  },
  data() {
    return {
      initCount: 0,
      pubKey: '',
      targetDom: null,
      domResize: null,
      domScroll: null,
      resizeDebounce: null,
      scrollDebounce: null,
      targetWidth: 0,
      targetHeight: 0,
      openCamera: [],
      openBackCamera: [],
      tempCamera: [],
      homeOpenCamera: [],
      defaultWndNum: 4,
      // oWebControl:null,
      temp_camera: {
        cameraIndexCode: '',
        result: 0,
        winId: 0
      },
      currentCamera: '',
      timeOutLoading: '',
      isFastClick: false
    }
  },

  watch: {
    // oWebControl(val) {
    //   console.log(val);
    // },
    // desVideo(val){
    //   console.log(val)
    //   if(oWebControl){
    //     if(val){
    //
    //     }
    //   }
    // },
    // playMode() {},
    value() {
      try {
        if (this.funcName !== 'startPlayback') {
          this.fn_preview__cameraIndexCode()
        } else {
          this.fn_back__cameraIndexCode()
        }
      } catch (error) {
        // console.log(error);
      }
    },
    appendVideo(val) {
      try {
        if (this.funcName !== 'startPlayback') {
          // console.log(val);
          this.fn_preview__cameraIndexCode(val)
        } else {
          this.fn_back__cameraIndexCode(val)
        }
        // this.fn_preview__cameraIndexCode(val);
      } catch (error) {
        // console.log(error);
      }
    },
    openCamera(val) {
      // console.log(val);
      this.$emit('open-camera', val)
    },
    openBackCamera(val) {
      this.$emit('open-camera', val)
    },
    homeOpenCamera(val) {
      this.$emit('home-open-camera', val)
    },

    showVideoLayout(val) {
      const _this = this
      const arg = {
        funcName: 'setLayout',
        argument: {
          layout: val + 'x' + val
        }
      }

      arg.argument = JSON.stringify(arg.argument)

      window[_this.oWebControl].JS_RequestInterface(arg).then(function(oData) {
        // console.log(oData);
        // showCBInfo(JSON.stringify(oData ? oData.responseMsg : ''));
        _this.openCamera = []
        _this.$emit('change-video', val)
      })
    },
    hideWnd(val) {
      if (this.oWebControl) {
        if (val) {
          // 组件加载时瞬间点击矫正车牌，以至于JS_HideWnd方法还未注册无法隐藏，此时wideWnd却已经改变为true导致监听方法无法触发
          window[this.oWebControl].JS_HideWnd()
        } else {
          window[this.oWebControl].JS_ShowWnd()
        }
      }
    }
  },
  created() {
    this.$emit('showTable', false)
  },
  mounted() {
    this.isFastClick = false
    window[this.oWebControl] = null
    this.timeOutLoading = setTimeout(() => {
      this.targetDom = document.querySelector('.video-panel-' + this.playWnd)
      this.targetWidth = this.targetDom.clientWidth
      this.targetHeight = this.targetDom.clientHeight
      this.fn_init__plugin()
    }, 1000)
  },
  beforeDestroy() {
    this.isFastClick = true
    window.clearTimeout(this.timeOutLoading)
    if (window[this.oWebControl] != null) {
      window[this.oWebControl].JS_HideWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
      // oWebControl.JS_Disconnect().then(
      //   function() {
      //     // 断开与插件服务连接成功
      //     console.log('断开与插件服务连接成功');
      //   },
      //   function() {
      //     // 断开与插件服务连接失败
      //     console.log('断开与插件服务连接失败');
      //   }
      // );
      // window[this.oWebControl].JS_DestroyWnd().then(function(res){console.log(res)},function(error){
      //
      // })
    }
    document.body.removeEventListener('resize', this.domResize)
    document.body.removeEventListener('scroll', this.domScroll)
  },
  methods: {
    // 创建WebControl实例与启动插件
    fn_init__plugin() {
      const $this = this
      // console.log(window[$this.oWebControl]);
      // console.log($this.oWebControl);
      window[$this.oWebControl] = new WebControl({
        szPluginContainer: $this.playWnd, // 指定容器id
        iServicePortStart: 15900, // 指定起止端口号，建议使用该值
        iServicePortEnd: 15900,
        szClassId: '23BF3B0A-2C56-4D97-9C03-0CB103AA8F11', // 用于IE10使用ActiveX的clsid
        cbConnectSuccess: function() {
          // console.log(window[$this.oWebControl]);
          // setCallbacks();
          // 实例创建成功后需要启动服务
          $this.$store.dispatch(
            'fn_save_oWebControl',
            window[$this.oWebControl]
          )
          window[$this.oWebControl]
            .JS_StartService('window', {
              dllPath: './VideoPluginConnect.dll'
            })
            .then(
              function() {
                // console.log('playWnd', window[$this.oWebControl]);
                window[$this.oWebControl].JS_SetWindowControlCallback({
                  cbIntegrationCallBack: $this._cbIntegrationCallBack
                })
                window[$this.oWebControl]
                  .JS_CreateWnd(
                    $this.playWnd,
                    $this.targetWidth,
                    $this.targetHeight
                  )
                  .then(function() {
                    // JS_CreateWnd创建视频播放窗口，宽高可设定
                    // console.log('JS_CreateWnd success');
                    if ($this.isFastClick) {
                      window[$this.oWebControl].JS_HideWnd()
                      // window[$this.oWebControl] = null;
                      return
                    }
                    $this.fn_init() // 创建播放实例成功后初始化
                  })
                  .catch(error => {
                    // console.log(error, $this);
                  })
              },
              function() {}
            )
        },
        cbConnectError: function() {
          // console.log('cbConnectError');
          window[$this.oWebControl] = null
          // console.log('插件未启动，正在尝试启动，请稍候...');
          WebControl.JS_WakeUp('VideoWebPlugin://') // 程序未启动时执行error函数，采用wakeup来启动程序
          $this.initCount++
          if ($this.initCount < 2) {
            setTimeout(function() {
              $this.fn_init__plugin()
            }, 2000)
          } else {
            $this.$alert(
              `<p>插件启动失败，请检查插件是否安装！若已安装，请刷新浏览器重试！</p><p><a style="font-weight: bold;font-size: 16px;color:#409eff" href='/cdn/hikvision/VideoWebPlugin.exe'>点击下载</a></p>`,
              '插件启动失败',
              {
                dangerouslyUseHTMLString: true
              }
            )
          }
        },
        cbConnectClose: function() {
          // console.log('cbConnectClose');
          window[$this.oWebControl] = null
        }
      })
      window[$this.oWebControl].JS_SetWindowControlCallback({
        cbIntegrationCallBack: function({ responseMsg }) {
          // console.log(responseMsg);
          const { type, msg } = responseMsg
          const { result, cameraIndexCode } = msg
          // oData 是封装的视频 web 插件回调消息的消息体
          if (type === 1) {
            $this.currentCamera = cameraIndexCode
            $this.$emit('select-code', cameraIndexCode)
          }
        }
      })
      this.domResize = this.fn_resize__debounce()
      this.domScroll = this.fn_scroll__debounce()
      window.addEventListener('resize', this.domResize)
      document.body
        .querySelector('#visual3d')
        .addEventListener('scroll', this.domScroll)
    },

    // 初始化
    fn_init() {
      const $this = this
      this.fn_get__pubKey(function() {
        // initConf
        window[$this.oWebControl]
          .JS_RequestInterface({
            funcName: 'init',
            argument: JSON.stringify({
              ...defaultInitConf,
              ...$this.options,
              secret: $this.fn_set__encrypt(
                $this.options.secret || defaultInitConf.secret
              )
            })
          })
          .then(function(oData) {
            // console.log('ok');
            // 提示已加载完成
            $this.$emit('showTable', true)
            window[$this.oWebControl].JS_Resize(
              $this.targetWidth,
              $this.targetHeight
            ) // 初始化后resize一次，规避firefox下首次显示窗口后插件窗口未与DIV窗口重合问题
            $this.fn_preview__cameraIndexCode()
          })
      })
    },

    // 设置窗口回调
    _cbIntegrationCallBack({ responseMsg }) {
      // console.log(responseMsg);
      const { msg, type } = responseMsg
      if (type === 1) {
        this.temp_camera = msg
      }
      if (type === 6) {
        this.openCamera = []
        this.$emit('change-video', msg.wndNum)
        this.defaultWndNum = msg.wndNum
      }
    },

    // 获取公钥
    fn_get__pubKey(callback) {
      const $this = this
      window[$this.oWebControl]
        .JS_RequestInterface({
          funcName: 'getRSAPubKey',
          argument: JSON.stringify({
            keyLength: 1024
          })
        })
        .then(function(oData) {
          if (oData.responseMsg.data) {
            $this.pubKey = oData.responseMsg.data
            callback()
          }
        })
    },

    // RSA加密
    fn_set__encrypt(value) {
      const encrypt = new JSEncrypt()
      encrypt.setPublicKey(this.pubKey)
      return encrypt.encrypt(value)
    },
    //
    // value为字符串，JS_RequestInterface仅接收json格式的变量，且需要先解析出argument，并且将argument字段的内容转为字符串
    fn_request__interface(JsonParam) {
      const $this = this
      const JsonArgument = JsonParam.argument
      JsonParam.argument = JSON.stringify(JsonArgument)
      window[this.oWebControl]
        .JS_RequestInterface(JsonParam)
        .then(function(oData) {
          // console.log(oData);
          // showCBInfo(JSON.stringify(oData ? oData.responseMsg : ''));
        })
    },
    /**
     * @desc 根据监控点编号视频预览 支持轮询，就不支持追加
     * @param {Object} options
     * @param {String} [options.authUuid] - authUuid为多平台接入时必须的播放字段，单平台接入时，可不指定
     * @param {String} options.cameraIndexCode - 监控点编号
     * @param {Number} [options.ezvizDirect=0] - 是否直连萤石预览(未指定或为 0-非直连 其它值-直连.使用直连萤石预览功能时，在初始化时要选择
     启用 https，若使用 http，则不支持直连萤石预览)
     * @param {Number} [options.gpuMode=0] - 是否启用 GPU 硬解(0-不启用 1-启用)
     * @param {Number} [options.streamMode=1] - 主子码流标识(0-主码流 1-子码流)
     * @param {Number} [options.wndId=-1] - 播放窗口序号(-1 或未指定-空闲窗口预览（不管成功与失败，预览后会自动跳到下一个空闲窗口） 0-选中窗口预览（预览后不会自动跳到下一个空闲窗口） 大于 0)
     * @param {Number} [options.transMode=1] - 传输协议(0-UDP 1-TCP)
     * @param {String} [funcName] - 功能标识
     */
    fn_preview__cameraIndexCode(cameraIndexCode = this.value) {
      const params = {
        // authUuid: '', // authUuid为多平台接入时必须的播放字段，单平台接入时，可不指定
        cameraIndexCode: '',
        ezvizDirect: 0,
        gpuMode: 0,
        streamMode: 1,
        transMode: 1,
        wndId: -1,
        cascade: 1
      }
      let arg = null
      const options = this.options
      let funcName = this.funcName
      if (typeof cameraIndexCode === 'string') {
        let index = -1
        if (this.temp_camera.cameraIndexCode.length !== 0) {
          index = this.openCamera.findIndex(
            item => item === this.temp_camera.cameraIndexCode
          )
          // 如果在播放通道中。不再执行下一步操作
          if (this.openCamera.includes(cameraIndexCode)) {
            return false
          }
          if (index !== -1) {
            Vue.set(this.openCamera, index, cameraIndexCode)
            params.wndId = this.temp_camera.wndId
          } else {
            Vue.set(
              this.openCamera,
              this.temp_camera.wndId - 1,
              cameraIndexCode
            )
            params.wndId = this.temp_camera.wndId
          }

          this.temp_camera.cameraIndexCode = cameraIndexCode
          // this.openCamera.splice(index, 1, cameraIndexCode);
          this.currentCamera = cameraIndexCode
          if (funcName === 'startMultiPreviewByCameraIndexCode') {
            cameraIndexCode = [cameraIndexCode]
            funcName = 'startPreview'
          }
          // cameraIndexCode = [cameraIndexCode];
          // funcName = 'startPreview';
        } else {
          // 如果在播放通道中。不再执行下一步操作
          if (this.openCamera.includes(cameraIndexCode)) {
            return false
          }
          Vue.set(this.openCamera, this.temp_camera.wndId - 1, cameraIndexCode)
          this.currentCamera = cameraIndexCode
          if (funcName === 'startMultiPreviewByCameraIndexCode') {
            cameraIndexCode = [cameraIndexCode]
            funcName = 'startPreview'
          }
          // cameraIndexCode = [cameraIndexCode];
          // funcName = 'startMultiPreviewByCameraIndexCode';
        }
        // let index = this.openCamera.findIndex(
        //   item => item=== cameraIndexCode
        // );
        // // 如果在播放通道中。不再执行下一步操作
        // if (this.openCamera.includes(cameraIndexCode)) {
        //   return false;
        // }
        // this.openCamera.splice(index, 1, cameraIndexCode);
        // this.currentCamera = cameraIndexCode;
        // if (funcName === 'startMultiPreviewByCameraIndexCode') {
        //   cameraIndexCode = [cameraIndexCode];
        //   funcName = 'startPreview';
        // }
      }
      if (funcName === 'startPreview') {
        Vue.set(this.homeOpenCamera, 0, cameraIndexCode[0])
        arg = {
          ...params,
          ...options,
          cameraIndexCode: cameraIndexCode[0]
        }
      } else if (funcName === 'startMultiPreviewByCameraIndexCode') {
        arg = {
          list: cameraIndexCode.map((item, index) => {
            return {
              ...params,
              ...options,
              wndId: index + 1,
              cameraIndexCode: item
            }
          })
        }
        this.currentCamera = cameraIndexCode[0]
        this.openCamera.push(...cameraIndexCode)
      }
      this.fn_request__interface({
        funcName,
        argument: arg
      })
      return {
        funcName,
        argument: arg
      }
    },

    /**
     * @desc 根据监控点编号录像回放
     */
    fn_back__cameraIndexCode(cameraIndexCode = this.value) {
      Vue.set(this.openBackCamera, 0, cameraIndexCode)
      // 前1天
      const date = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
      const current = new Date()
      const month =
        date.getMonth() + 1 < 10
          ? '0' + (date.getMonth() + 1)
          : date.getMonth() + 1
      const currentMonth =
        current.getMonth() + 1 < 10
          ? '0' + (current.getMonth() + 1)
          : current.getMonth() + 1
      const startYear = current.getFullYear() - 1
      // 开始时间当天00点
      // let str = startYear + '/' + '01' + '/' + '01' + ' 00:00:00'
      const str =
        date.getFullYear() + '/' + month + '/' + date.getDate() + ' 00:00:00'
      const currentStamp =
        current.getFullYear() +
        '/' +
        currentMonth +
        '/' +
        current.getDate() +
        ' 00:00:00'
      const playTimeStamp = String(
        parseInt(new Date(currentStamp).getTime() / 1000) - 3 * 60 * 60
      )
      const startTime = String(
        parseInt(new Date(str).getTime() / 1000) - 3 * 60 * 60
      )
      const endTime = String(parseInt(current.getTime() / 1000))
      const arg = {
        funcName: 'startPlayback',
        argument: {
          cameraIndexCode: cameraIndexCode.trim(), // 监控点编号
          startTimeStamp: startTime, // 录像查询开始时间戳，单位：秒
          endTimeStamp: endTime, // 录像查询结束时间戳，单位：秒
          playTimeStamp: playTimeStamp,
          recordLocation: 1, // 录像存储类型 0-中心存储 1-设备存储
          transMode: 0, // 传输协议 ，0-UDP 1-TCP
          gpuMode: 0 // 是否开启 GPU 硬解，0-不开启 1-开启
        }
      }
      this.fn_request__interface(arg)
    },
    /**
     * @desc 时间格式化
     */
    fn_format__date(oDate, fmt) {
      const o = {
        'M+': oDate.getMonth() + 1, // 月份
        'd+': oDate.getDate(), // 日
        'h+': oDate.getHours(), // 小时
        'm+': oDate.getMinutes(), // 分
        's+': oDate.getSeconds(), // 秒
        'q+': Math.floor((oDate.getMonth() + 3) / 3), // 季度
        S: oDate.getMilliseconds() // 毫秒
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(
          RegExp.$1,
          (oDate.getFullYear() + '').substr(4 - RegExp.$1.length)
        )
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(
            RegExp.$1,
            RegExp.$1.length == 1
              ? o[k]
              : ('00' + o[k]).substr(('' + o[k]).length)
          )
        }
      }
      return fmt
    },

    /**
     * @desc 根据监控点编号批量视频预览
     * @param {Array} list
     *
     * @param {Object} list[index]
     */
    fn_multi_preview__cameraIndexCode() {
      return this.fn_preview__cameraIndexCode(
        list,
        'startMultiPreviewByCameraIndexCode'
      )
    },
    fn_util__isJSON(str) {
      if (typeof str === 'string') {
        try {
          var obj = JSON.parse(str)
          if (typeof obj === 'object' && obj) {
            return true
          } else {
            showCBInfo('param is not the correct JSON message')
            return false
          }
        } catch (e) {
          showCBInfo('param is not the correct JSON message')
          return false
        }
      }
      // console.log('It is not a string!');
    },
    // 停止所有视频预览
    fn_stop__allPreview() {},
    // 监听resize事件，使插件窗口尺寸跟随DIV窗口变化
    fn_resize__debounce() {
      const $this = this
      return fn_util_debounce(
        function() {
          const targetDom = document.querySelector(
            '.video-panel-' + $this.playWnd
          )
          if (targetDom && window[$this.oWebControl] != null) {
            const targetWidth = targetDom.clientWidth
            const targetHeight = targetDom.clientHeight
            window[$this.oWebControl].JS_Resize(targetWidth, targetHeight)
          }
        },
        this,
        800
      )
    },

    // 监听滚动条scroll事件，使插件窗口跟随浏览器滚动而移动
    fn_scroll__debounce() {
      const $this = this
      return () => {
        if (window[this.oWebControl] != null) {
          window[$this.oWebControl].JS_Resize(
            $this.targetWidth,
            $this.targetHeight
          )
          try {
            $this.fn_set__wndCover()
          } catch (error) {
            // console.log(error);
          }
        }
      }
    },

    // 设置窗口裁剪，当因滚动条滚动导致窗口需要被遮住的情况下需要JS_CuttingPartWindow部分窗口
    fn_set__wndCover() {
      const $this = this
      const iWidth = document.body.clientWidth
      const iHeight = document.body.clientHeight
      // let dom = document.querySelector('.video-panel');
      this.targetWidth = this.targetDom.clientWidth
      this.targetHeight = this.targetDom.clientHeight

      const oDivRect = document
        .querySelector('#' + $this.playWnd)
        .getBoundingClientRect()
      let iCoverLeft = oDivRect.left < 0 ? Math.abs(oDivRect.left) : 0
      let iCoverTop = oDivRect.top < 0 ? Math.abs(oDivRect.top) : 0
      let iCoverRight =
        oDivRect.right - iWidth > 0 ? Math.round(oDivRect.right - iWidth) : 0
      let iCoverBottom =
        oDivRect.bottom - iHeight > 0
          ? Math.round(oDivRect.bottom - iHeight)
          : 0

      iCoverLeft =
        iCoverLeft > $this.targetWidth ? $this.targetWidth : iCoverLeft
      iCoverTop =
        iCoverTop > $this.targetHeight ? $this.targetHeight : iCoverTop
      iCoverRight =
        iCoverRight > $this.targetWidth ? $this.targetWidth : iCoverRight
      iCoverBottom =
        iCoverBottom > $this.targetHeight ? $this.targetHeight : iCoverBottom

      window[$this.oWebControl].JS_RepairPartWindow(
        0,
        0,
        $this.targetWidth + 1,
        $this.targetHeight
      ) // 多1个像素点防止还原后边界缺失一个像素条
      if (iCoverLeft != 0) {
        window[$this.oWebControl].JS_CuttingPartWindow(
          0,
          0,
          iCoverLeft,
          $this.targetHeight
        )
      }
      if (iCoverTop != 0) {
        window[$this.oWebControl].JS_CuttingPartWindow(
          0,
          0,
          $this.targetWidth + 1,
          iCoverTop
        ) // 多剪掉一个像素条，防止出现剪掉一部分窗口后出现一个像素条
      }
      if (iCoverRight != 0) {
        window[$this.oWebControl].JS_CuttingPartWindow(
          $this.targetWidth - iCoverRight,
          0,
          iCoverRight,
          $this.targetHeight
        )
      }
      if (iCoverBottom != 0) {
        window[$this.oWebControl].JS_CuttingPartWindow(
          0,
          $this.targetHeight - iCoverBottom,
          $this.targetWidth,
          iCoverBottom
        )
      }
    },

    /**
     * @desc 实时回放和历史回放切换时，切换数据源
     */
    fn_changle_camera(val) {
      if (val === 'startPlayback') {
        this.$emit('open-camera', this.openBackCamera)
      } else {
        this.$emit('open-camera', this.openCamera)
      }
    },
    // 销毁组件
    fn_destory_compontent() {
      // console.log(this.oWebControl + '被销毁了');
      window.clearTimeout(this.timeOutLoading)
      if (window[this.oWebControl] != null) {
        window[this.oWebControl].JS_DestroyWnd() // 先让窗口隐藏，规避可能的插件窗口滞后于浏览器消失问题
        // oWebControl.JS_Disconnect().then(
        //   function() {
        //     // 断开与插件服务连接成功
        //     console.log('断开与插件服务连接成功');
        //   },
        //   function() {
        //     // 断开与插件服务连接失败
        //     console.log('断开与插件服务连接失败');
        //   }
        // );
        // window[this.oWebControl].JS_DestroyWnd().then(function(res){console.log(res)},function(error){
        //
        // })
      }
      document.body.removeEventListener('resize', this.domResize)
      document.body.removeEventListener('scroll', this.domScroll)
    }
  }
}
</script>
<style lang="scss" scoped>
.video-content {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.video-box {
  width: 100%;
  height: 100%;
}
video:focus {
  outline: unset;
}
</style>
